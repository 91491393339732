<template>
  <van-tab v-bind="$attrs">
    <div class="tab-content">
      <!-- <van-cell
          v-if="taskDetail.inspectionStatus === 'NOT_FORMED'"
        title="报告状态"
        value="未形成"
        size="large"
        :border="false"
        value-class="task_status-undone"
      />
      <van-cell
          v-else-if="taskDetail.inspectionStatus === 'SUBMITTED'"
        title="报告状态"
        value="验货员已提交"
        size="large"
        :border="false"
        value-class="task_status-submitted"
      />
      <van-cell
          v-else-if="taskDetail.inspectionStatus === 'SUPPLIER_CONFIRMED'"
        title="报告状态"
        value="供应商已确认"
        size="large"
        :border="false"
        value-class="task_status-confirmed"
      /> -->
      <!-- <TaskInfoCard></TaskInfoCard> -->
      <card title="验货小结">
        <div class="info-item space-between">
          <div class="label">验货订单数</div>
          <div class="value">{{ inspectDetail.taskOrderTotal }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货单品品类数</div>
          <div class="value">{{ inspectDetail.orderItemTotal }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">点货总数</div>
          <div class="value">{{ inspectDetail.orderTotal }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">抽检总数</div>
          <div class="value">{{ inspectDetail.sampleTotal }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">缺陷总计</div>
          <div class="value">
            CRI {{ inspectDetail.aqlCriTotal }} MAJ
            {{ inspectDetail.aqlMajTotal }} MIN {{ inspectDetail.aqlMinTotal }}
          </div>
        </div>
      </card>
      <card title="订单列表" class="order-list">
        <van-collapse v-model="activeName">
          <van-collapse-item
            v-for="(order, index) in taskDetail.taskOrders"
            :key="index"
            :name="index"
          >
            <div class="order-item space-between vertical-center" slot="title">
              <img
                :class="{ 'is-active': activeName.includes(index) }"
                src="~@/assets/svg/icon-caret.svg"
                alt=""
              />
              <div class="title">订单号：{{ order.orderNo }}</div>
              <div v-if="order.taskOrderItems" class="num">
                {{ order.taskOrderItems.length }}款
              </div>
              <div v-else></div>
            </div>
            <div class="sub-order">
              <div class="table-th">
                <div>单品货号</div>
                <div>单品名称</div>
                <div>验货结论</div>
                <div class="th-num">供应商意见</div>
              </div>
              <div
                v-for="(item, index2) in order.taskOrderItems"
                :key="index2"
                class="sub-order-item"
                @click="handleOrderItemClick(item)"
              >
                <div class="table-td">
                  <div class="highlight">{{ item.singleProductNo }}</div>
                  <div class="highlight">
                    <span>{{ item.singleProductName }}</span>
                  </div>
                  <!-- 验货结论 -->
                    <div v-if="item.inspectConclusion === 'REJECT'" class="warn">
                    {{ INSPECT_DICT[item.inspectConclusion] }}
                  </div>
                  <div v-else-if="item.PASSED === 'REJECT'" class="highlight">
                    {{ INSPECT_DICT[item.inspectConclusion] }}
                  </div>
                  <div v-else class="wait">
                    {{ INSPECT_DICT[item.inspectConclusion] }}
                  </div>
                  <!-- 供应商意见 -->
                  <div class="td-num">
                    <!-- {{ item.orderQuantity }}/{{
                      item.actualSampleQuantity || item.sampleQuantity
                    }} -->
                     {{ gongyingshangyijian(item.supplierComments) }}
                  </div>
                
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </card>
      <card :title="'总验货报告通过情况：'+yanhuobaogao(taskDetail.inspectConclusion)">
        <!-- inspectionStatus -->
        <el-input
          type="textarea"
          autosize
          v-model="taskDetail.remark"
          disabled
        >
        </el-input>
      </card>
      <!-- <card v-else="taskDetail.inspectConclusion">
        <template slot="title">
          是否通过总验货报告 <span :class="taskDetail.inspectConclusion">{{TASK_INSPECT_DICT[taskDetail.inspectConclusion]}}</span>
        </template>
        <div class="info-item normal">
          {{taskDetail.inspectRemark}}
        </div>
      </card> -->
    </div>
    <div class="fixed-btn" v-if="false">
      <van-button
        v-if="taskDetail.inspectConclusion"
        type="primary"
        color="#055C9D"
        block
        @click="handleNavToIndex"
      >
        返回首页
      </van-button>
      <!-- <van-button
        v-else
        type="primary"
        :loading="submitLoading"
        loading-text="提交中"
        color="#055C9D"
        block
        @click="submitForm"
      >
        提交结论
      </van-button> -->
    </div>
  </van-tab>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import Divider from "@/components/Common/Divider.vue";
import TaskInfoCard from "@/components/TaskDetail/TaskInfoCard";
import { postAction, getAction } from "@/api/manage";
import { TASK_INSPECT_DICT, INSPECT_DICT } from "@/constants";

export default {
  name: "TaskInfoTab",
  components: { TaskInfoCard, Divider, Card },
  props: {
    id: {
      type: String,
      required: true,
    },
    taskDetail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      textarea1:'验货备注',
      activeName: [],
      submitLoading: false,
      INSPECT_DICT,
      TASK_INSPECT_DICT,
      form: {
        inspectStatus: "",
        reason: "",
      },
      inspectDetail: {},
    };
  },
  created() {
    this.getInspectStat();
  },
  mounted(){
    console.log(this.taskDetail);
  },
  methods: {
    // 验货报告
    yanhuobaogao(val){
      if(val=='PASSED')return '通过'
      if(val=='NOT_PASS')return '未通过'
      if(val=='PENDING')return '待定'
      if(!val)return '无'
    },
    // 供应商意见
    gongyingshangyijian(val){
       if(val=='AGREE')return '同意'
      if(val=='DISAGREE')return '不同意'
      if(val=='PENDING')return '待定'
      if(val=='AUTO_CONFIRM')return '自动确认'
    },
    handleInputBlur() {
      window.scroll(0, 0);
    },
    handleOrderItemClick(item) {
      if (item.inspectConclusion) {
        this.$router.push({
          name: `taskCheck`,
          query: {
            ...this.$route.query,
            step: "4",
            singleProductId: item.singleProductId,
            orderItemId: item.id,
          },
        });
      }
    },
    handleCall(phone) {
      window.location.href = `tel:${phone}`;
    },
    handleNavToIndex() {
      this.$router.replace({ name: "index", query: { ...this.$route.query } });
    },
    async submitForm() {
      if (!this.form.inspectStatus) {
        this.Toast.fail("请选择验货结论");
        return;
      }
      this.submitLoading = true;
      const res = await postAction(
        "/jeecg-system/admin/taskBaseInfo/add_inspect_info",
        {
          id: this.taskDetail.id,
          inspectStatus: this.TASK_INSPECT_DICT[this.form.inspectStatus],
          reason: this.form.reason,
        }
      );
      this.submitLoading = false;
      if (res.success) {
        this.$emit("on-refresh");
      } else this.Toast.fail(res.message);
    },
    async getInspectStat() {
      const res = await getAction(
        "/jeecg-system/admin/taskBaseInfo/inspect_stat",
        {
          id: this.id,
        }
      );
      if (res.success) {
        this.inspectDetail = res.result;
      } else this.Toast.fail(res.message);
    },
  },
};
</script>

<style scoped lang="less">
/deep/.tab-content {
  // height: calc(100vh - 172px);
  .van-cell--large {
    margin-top: 16px;
    .task_status-undone {
      color: #f75658;
    }
    .task_status-submitted {
      color: #ffad16;
    }
    .task_status-confirmed {
      color: #055c9d;
    }
  }
  .warn {
    color: #f75658;
  }
  .highlight,
  .PASSED {
    color: #055c9d;
  }
  .NOT_PASS {
    color: #e34d59;
  }
  .wait,
  .PENDING {
    color: #ffad16;
  }
  .normal {
    color: rgba(0, 0, 0, 0.4);
  }
  .icon-phone {
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }
  .info-item {
    line-height: 20px;
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .label {
      width: 80px;
      font-size: 12px;
      color: rgba(#000, 0.4);
    }
    .value {
      width: calc(100% - 80px);
      font-size: 14px;
      & > div {
        margin-top: 8px;
        // line-height: 22px;
      }
    }
  }
  .work-flow {
    padding-bottom: 16px;
    .flow-item {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .available-date {
    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding: 12px 0;
    }
  }
  .van-collapse-item__title {
    padding: 12px 8px;
  }
  .order-item {
    justify-content: flex-start;
    line-height: 22px;

    img {
      width: 16px;
      height: 16px;
      &.is-active {
        transform: rotate(90deg);
      }
    }
    // .title {
    //   width: 241px;
    // }
    .num {
      flex: 1 1;
      text-align: right;
    }
  }
  .sub-order {
    border-bottom: 1px solid #dfe4eb;
    .table-th,
    .table-td {
      display: flex;
      height: 40px;
      color: rgba(0, 0, 0, 0.6);

      & > div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        flex: 1 1;
        span {
          width: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          text-align: center;
        }
        &.th-num,
        &.td-num {
          flex: none;
          width: 103px;
        }
      }
    }
  }
  .van-collapse-item__content {
    padding: 0;
  }
  .van-cell::after,
  .van-cell__right-icon {
    display: none;
  }
  .order-list {
    .van-cell {
      border-bottom: 1px solid #dfe4eb;
    }
    padding: 0;
    & > .title,
    .divider {
      margin: 0 16px;
    }
    .card-content {
      padding: 0 8px;
    }
  }
  .van-hairline--top-bottom::after {
    border: 0;
  }
  .radio-group {
    // width: calc(100% - 80px);
    .van-radio-group {
      justify-content: space-between;
    }
    .img-icon {
      width: 12px;
      height: 12px;
    }

    // ::v-deep {
    .van-radio {
      margin-right: 0;
      // flex: 1 1;
      &:last-child {
        margin-right: 0;
      }
    }

    .van-radio__label {
      margin-left: 4px;
      height: 22px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 22px;
    }
    // }
  }
  .textarea-box {
    margin-top: 8px;

    .el-textarea__inner {
      background: #f9f9f9;
      border-radius: 4px;
      border: 0;
    }
  }
}
</style>
