<template>
  <van-tab :title="title" v-bind="$attrs">
    <div class="tab-content">
      <van-collapse v-model="activeName">
        <van-collapse-item
          v-for="(order, index) in taskDetail.taskOrders"
          :key="index"
          :name="index"
        >
          <div class="order-item vertical-center" slot="title">
            <img
              :class="{ 'is-active': activeName.includes(index) }"
              src="~@/assets/svg/icon-caret.svg"
            />
            <div class="title">订单号：{{ order.orderNo }}</div>
            <div v-if="order.taskOrderItems" class="num">
              {{ order.taskOrderItems.length }}款
            </div>
            <div v-else></div>
          </div>
          <div
            v-for="(item, index2) in order.taskOrderItems"
            :key="index2"
            class="sub-order space-between"
          >
            <!--  -->
            <div>
              <div class="content" @click="handleNavTo(item)">
                <div class="icon_status">
                  <div
                    v-if="item.supplierComments == 'AGREE'"
                    class="AGREE"
                  ></div>
                  <div
                    v-if="item.supplierComments == 'DISAGREE'"
                    class="DISAGREE"
                  ></div>
                  <div
                    v-if="item.supplierComments == 'PENDING'"
                    class="PENDING"
                  ></div>
                  <div
                    v-if="item.supplierComments == 'AUTO_CONFIRM'"
                    class="AUTO_CONFIRM"
                  ></div>
                  <!-- <img
                    v-if="item.supplierComments == 'DISAGREE'"
                    src="../../assets/svg/chahao.svg"
                    alt=""
                  /> -->
                  <!-- <img
                    v-if="item.supplierComments == 'AUTO_CONFIRM'"
                    src="../../assets/svg/lishi-lan.svg"
                    alt=""
                  /> -->
                  <!-- <img
                    v-if="item.supplierComments == 'PENDING'"
                    src="../../assets/svg/lishi-lv.svg"
                    alt=""
                  /> -->
                </div>
                <div class="name">
                  {{ item.singleProductNo }}-{{ item.singleProductName }}
                  <div v-if="item.supplierComments == 'AGREE'">已确认同意</div>
                  <div v-if="item.supplierComments == 'DISAGREE'">已确认不同意</div>
                  <div v-if="item.supplierComments == 'PENDING'">
                    距系统自动确认还有：{{ ChangeTime(item.lastCommitTime) }}
                  </div>
                  <div v-if="item.supplierComments == 'AUTO_CONFIRM'">
                    系统已自动确认
                  </div>
                </div>

                <!-- 'PASSED': '通过', 'REJECT': '拒收', 'UNTESTED': '未验','PENDING': '待定', -->
                <!-- 验货员结论 -->
                <div
                  style="
                    display: flex;
                    align-items: center;
                    flex: 1;
                    justify-content: end;
                  "
                >
                  <span
                    v-if="item.inspectConclusion == 'PASSED'"
                    style="
                      color:#00BF46 !important;
                      width: 50px;
                      text-align: right;
                      font-size: 14px;
                    "
                    >通过</span
                  >
                  <span
                    v-if="item.inspectConclusion == 'REJECT'"
                    style="
                      color: #f94837 !important;
                      width: 50px;
                      text-align: right;
                      font-size: 14px;
                    "
                    >拒收</span
                  >
                  <span
                    v-if="item.inspectConclusion == 'UNTESTED'"
                    style="
                      color: #055c9d !important;
                      width: 50px;
                      text-align: right;
                      font-size: 14px;
                    "
                    >未验货</span
                  >
                  <span
                    v-if="item.inspectConclusion == 'PENDING'"
                    style="
                      color: #ffad16 !important;
                      width: 50px;
                      text-align: right;
                      font-size: 14px;
                    "
                    >待定</span
                  >
                  &nbsp;<svg
                    t="1663568422421"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="3820"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M682.666667 533.333333a21.333333 21.333333 0 0 1-15.146667-6.186666l-298.666667-298.666667a21.333333 21.333333 0 0 1 30.293334-30.293333l298.666666 298.666666a21.333333 21.333333 0 0 1 0 30.293334A21.333333 21.333333 0 0 1 682.666667 533.333333z"
                      fill="#333333"
                      p-id="3821"
                    ></path>
                    <path
                      d="M384 832a21.333333 21.333333 0 0 1-15.146667-6.186667 21.333333 21.333333 0 0 1 0-30.293333l298.666667-298.666667a21.333333 21.333333 0 0 1 30.293333 30.293334l-298.666666 298.666666A21.333333 21.333333 0 0 1 384 832z"
                      fill="#333333"
                      p-id="3822"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div class="btn_box" v-if="item.supplierComments == 'PENDING'">
              <el-button
                type="primary"
                round
                style="color: #fff; background-color: #065b9e"
                size="small"
                @click="openPopup(item)"
                >同意</el-button
              >
              <el-button
                type=""
                plain
                round
                style="color: #4179a9; width: 80px"
                size="small"
                @click="UnAgreeOpenPopup(item)"
                >不同意</el-button
              >
            </div>
            <!-- <div class="btn_box" v-if="item.supplierComments == 'DISAGREE'">
              <el-button
                type="primary"
                round
                style="color: #fff; background-color: #065b9e"
                size="small"
                @click="openPopup(item)"
                >同意</el-button
              >
              <el-button
                type=""
                plain
                round
                style="color: #4179a9; width: 80px"
                size="small"
                @click="UnAgreeOpenPopup(item)"
                >不同意</el-button
              >
            </div> -->
          </div>
        </van-collapse-item>
      </van-collapse>
      <van-popup v-model="showPopupAgree">
        <div style="width: 320px; height: 225px" class="tancen">
          <p>温馨提示</p>
          <div class="tancen_content">
            同意验货结论后，将被视为经验货员与供应商双方同意后的最终结论，不再接受申诉。
          </div>
          <div class="tancen_BTN_box">
            <van-button color="#055C9D" @click="UnAgreeOpenPopup" block
              >不同意验货结论</van-button
            >
          </div>
        </div>
      </van-popup>

      <van-dialog
        v-model="showPopupUnAgree"
        title="不同意验货结论的理由申诉"
        show-cancel-button
        @cancel="chanceBtn"
        @confirm="confirmBtn"
      >
        <van-form>
          <van-field
            style="flex-direction: column"
            v-model="form.supplierAppealReason"
            name="申诉理由"
            label="申诉理由"
            :rules="[{ required: true, message: '请填写申诉理由' }]"
            autosize
            rows="2"
            type="textarea"
            maxlength="50"
            show-word-limit
          />
          <p
            style="
              padding-left: 0.42667rem;
              color: #646566;
              text-align: left;
              font-size: 0.37333rem;
            "
          >
            证据照片
          </p>
          <van-uploader
            v-model="imgList"
            :preview-size="70"
            :upload-icon="require('@/assets/png/paizhao.png')"
            style="padding-left: 0.42667rem"
            :after-read="handleReadImg"
          />
          <!-- <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div> -->
        </van-form>
      </van-dialog>
    </div>
  </van-tab>
</template>

<script>
import { axios } from "@/utils/request";
import { SAMPLING_LEVEL_DICT } from "@/constants";
import { Dialog, Toast } from "vant";
import { putAction } from "@/api/manage";
import { TASK_INSPECT_DICT, INSPECT_DICT } from "@/constants";
import dayjs from "dayjs";
export default {
  name: "GonghuoTaskDetailTab",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    taskDetail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      imgList: [],
      form: {
        supplierAppealReason: "",
        supplierAppealImg: "",
      },
      INSPECT_DICT,
      SAMPLING_LEVEL_DICT,
      activeName: [],
      showPopupUnAgree: false, //不同意是否弹出
      showPopupAgree: false, //同意是否弹出
      UnAgreeId: "", //不同意提交id
    };
  },
  computed: {
    isAllChecked() {
      return false;
    },
  },
  methods: {
    ChangeTime(time) {
      let haomiao =
        dayjs(time).add(2, "day").valueOf() - dayjs(new Date()).valueOf();
      var hours = parseInt(
        (haomiao % (1000 * 60 * 60 * 48)) / (1000 * 60 * 60)
      );
      var minutes = parseInt((haomiao % (1000 * 60 * 60)) / (1000 * 60));
      return hours + "小时" + minutes + "分";
    },
    handleReadImg(info, name) {
      const data = new FormData();
      data.append("file", info.file);
      axios({
        url: "jeecg-system/platform/common/upload_file",
        method: "post",
        data,
        headers: {
          "Content-Type": "multipart/form-data", // 文件上传
        },
      })
        .then((res) => {
          if (res.success) {
            info.url = res.message;
            info.content = res.message;
          } else {
            // 上传失败后，删除该图片
            let index = this.form.imgList.findIndex(
              (item) => item.content === info.content
            );
            if (index > -1) {
              this.form.imgList.splice(index, 1);
            }
            this.Toast.fail(res.message || "上传图片失败!");
          }
        })
        .catch((error) => {
          // 上传失败后，删除该图片
          let index = this.form.imgList.findIndex(
            (item) => item.content === info.content
          );
          if (index > -1) {
            this.form.imgList.splice(index, 1);
          }
          this.Toast.fail("上传图片失败");
        });
    },
    openPopup(item) {
      console.log(item, item.id);
      // this.showPopupAgree = !this.showPopupAgree;
      Dialog.confirm({
        title: "温馨提示",
        message:
          "同意验货结论后，将被视为经验货员与供应商双方同意后的最终结论，不再接受申诉。",
      }).then(() => {
        // on confirm
        putAction("/jeecg-system/task/taskOrderItem/edit", {
          id: item.id,
          supplierComments: "AGREE",
        }).then((res) => {
          location.reload();
        });
      });
      // .catch(() => {
      //   // on cancel
      // });
    },
    UnAgreeOpenPopup(item) {
      this.showPopupUnAgree = !this.showPopupUnAgree;
      this.UnAgreeId = item.id;
    },
    handleDownloadPdf() {
      console.log("下载报告pdf");
    },
    // 不同意 取消按钮
    chanceBtn() {
      this.showPopupUnAgree = false;
      this.form = {};
      this.imgList = [];
    },
    // 不同意 提交按钮
    confirmBtn() {
      // console.log(this.form,this.imgList);
      if (this.form.supplierAppealReason) {
        let dataList = JSON.parse(JSON.stringify(this.form));
        dataList.id = this.UnAgreeId;
        dataList.supplierComments = "DISAGREE";
        if (this.imgList.length > 0) {
          this.imgList.map((item) => {
            dataList.supplierAppealImg += item.url + ",";
          });
          dataList.supplierAppealImg = dataList.supplierAppealImg.substr(
            0,
            dataList.supplierAppealImg.length - 1
          );
        }
        putAction("/jeecg-system/task/taskOrderItem/edit", dataList).then(
          (res) => {
            if (res.success) {
              Toast.success("提交成功!");
              this.chanceBtn();
              location.reload();
            } else {
              this.Toast.fail(res.message);
            }
          }
        );
      }
    },
    handleNavTo(item) {
      this.$router.push({
        name: "GongHuoCheckDetail",
        query: {
          ...this.$route.query,
          singleProductId: item.singleProductId,
          orderItemId: item.id,
          step: "4",
        },
      });
      // if (item.status === "COMPLETED") {
      //   this.$router.push({
      //     name: "GongHuoCheckDetail",
      //     query: {
      //       ...this.$route.query,
      //       singleProductId: item.singleProductId,
      //       orderItemId: item.id,
      //       step: "4",
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name: "GongHuoCheckDetail",
      //     query: {
      //       ...this.$route.query,
      //       singleProductId: item.singleProductId,
      //       orderItemId: item.id,
      //       active: "basic",
      //     },
      //   });
      // }
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.btn_box {
  padding-left: calc(0.5rem + 24px);
  text-align: right;
  > button {
    width: 80px;
    height: 30px;
    border: 1px solid #065b9e !important;
  }
}
.tab-content::v-deep {
  height: calc(100vh);
  padding-top: 16px;
  padding-bottom: 86px;
  box-sizing: border-box;
  .sub-order {
    display: block;
    padding: 0.10667rem 0.21333rem 0.10667rem 0.36667rem !important;
    // border-bottom: none !important;
  }
  .van-collapse-item__title {
    padding: 12px 8px;
  }
  .order-item {
    justify-content: flex-start;
    line-height: 22px;

    img {
      width: 16px;
      height: 16px;

      &.is-active {
        transform: rotate(90deg);
      }
    }

    // .title {
    //   width: 267px;
    // }
    .num {
      flex: 1 1;
      text-align: right;
    }
  }

  .sub-order {
    padding: 4px 8px 4px 40px;
    border-bottom: 1px solid #dfe4eb;

    .content {
      display: flex;
      align-content: center;
      // width: calc(100% - 60px);
      .icon_status {
        display: flex;
        align-items: center;
        div {
          height: 16px;
          width: 16px;
        }
        .AGREE {
          background-image: url(../../assets/svg/duihao.svg);
        }
        .PENDING {
          background-image: url(../../assets/svg/lishi-lv.svg);
        }
        .DISAGREE {
          background-image: url(../../assets/svg/chahao.svg);
        }
        .AUTO_CONFIRM {
          background-image: url(../../assets/svg/lishi-lan.svg);
        }
      }
    }
    .name {
      padding: 0rem 0.1rem 0.1rem 0.5rem;
      > div {
        color: #969799;
      }
    }
    .name,
    .finish {
      font-size: 14px;
      line-height: 22px;
      color: rgba(#000, 0.9);
    }

    .icon-wrapper {
      width: 48px;
      flex-shrink: 0;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .van-cell {
    border-bottom: 1px solid #dfe4eb;
  }

  .van-collapse-item__content {
    // display: flex;
    // align-items: center;
    padding: 0;
    border-bottom: 0.02667rem solid #dfe4eb;
  }

  .van-cell::after,
  .van-cell__right-icon {
    display: none;
  }
}
</style>
