<template>
  <div class="task-detail">
    <global-header title="验货任务"/>
    <gong-huo-tab-page>
      <!-- <task-info-tab title="待确认验货清单" name="basic" :id="taskId" :task-detail="taskDetail"/> -->
      <GonghuoTaskDetailTab title="待确认验货清单" name="task" :id="taskId" :task-detail="taskDetail"/>
      <GonghuoTaskReportTab title="验货报告" name="report" :id="taskId" :task-detail="taskDetail" @on-refresh="getTaskDetail"/>
    </gong-huo-tab-page>
  </div>
</template>

<script>
import GlobalHeader from '@/components/Common/GlobalHeader.vue'
import GongHuoTabPage from '@/components/Common/GongHuoTabPage.vue'
import TaskInfoTab from '@/components/TaskDetail/TaskInfoTab.vue'
import GonghuoTaskDetailTab from '@/components/GonghuoTaskDetail/GonghuoTaskDetailTab.vue'
import GonghuoTaskReportTab from '@/components/GonghuoTaskDetail/GonghuoTaskReportTab.vue'
import {getAction} from '@/api/manage';
import inobounce from 'inobounce';

export default {
  name: 'GongHuoTaskDetail',
  components: {GonghuoTaskDetailTab, TaskInfoTab,GonghuoTaskReportTab, GongHuoTabPage, GlobalHeader},
  data() {
    return {
      taskId: this.$route.query.taskId,
      taskDetail: {}
    }
  },
  created() {
    this.getTaskDetail()
  },
  mounted() {
    let u = navigator.userAgent
    if (u.indexOf('iPhone') > -1) {
      inobounce.enable()
    }
  },
  beforeDestroy() {
    inobounce.disable()
  },
  methods: {
    async getTaskDetail() {
      this.Toast.allowMultiple()
      this.Toast.loading({
        message: '加载中',
      })
      const res = await getAction('/jeecg-system/admin/taskBaseInfo/queryById', {
        id: this.taskId
      })
      this.Toast.clear()
      if (res.success) {
        this.taskDetail = res.result
        // this.taskDetail.yanhuoFollow = `1、点数抽样\n2、条码扫描\n3、拍摄照片\n4、核对检验清单\n5、提交报告`
        this.taskDetail.title = `${res.result.companyName}-${res.result.yanhuoType_dictText}-${res.result.taskNo}`
        this.$store.state.taskDetail = this.taskDetail
      } else {
        this.Toast.fail(res.message)
      }
    },
  }
}
</script>

<style scoped>

</style>
